<template>
    <div>
        {{getCirculatingSupply}}
    </div>
</template>

<script>
import BigNumber from 'bignumber.js'
    export default {
        components: {},
        data() {
            return {
            }
        },
        computed: {
            getCirculatingSupply() {
                return  BigNumber(this.$store.state.balance.circulatingSupply).toString();
            },
        },
        methods: {
        }
    }
</script>

<style>
</style>
